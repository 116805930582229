<template>
  <div class="p-1px border-round-12px border">
    <div class="lines flex flex-row">
      <div v-if="value" :class="'line border-round-12px'" :style="{width: value +'%'}"></div>
      <div v-if="value2" :class="'line border-round-12px'" :style="{width: value +'%'}"></div>
      <div v-if="value3" :class="'line border-round-12px'" :style="{width: value +'%'}"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Lines",
  props: ["value", 'value2','value3'],
  methods: {
    ceil(v) {
      return Math.ceil(v || 0);
    },
  },
};
</script>
