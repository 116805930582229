import Axios from "axios";
import moment from "moment";
import sha256 from "sha256";
import Vue from "vue";

var url = process.env.VUE_APP_LOG_API || "http://api-log.gratisoft.tech";
var key = process.env.VUE_APP_LOG_KEY || "jhABrXqx2a65cLFv5nzcrx7u6ZRHX3vK";
var clientId = "pos-web";

export const logHistory = (listRaw) => {
  var list = listRaw.map((a) => {
    var d =  {
      user: a.user || Vue.prototype.$user.fullName || "",
      branch: a.branch || Vue.prototype.$branch.branchName,
      url: window.location.href,
      type: a.type || "",
      code: a.code || "",
      model: a.model || "",
      oldData: a.oldData || "",
      newData: a.newData || "",
      label: a.label,
      companyCode: Vue.prototype.$company.companyCode,
    };

    // d = Object.keys(d)
    // .sort()
    // .reduce(function (acc, key) { 
    //     acc[key] = d[key];
    //     return acc;
    // }, {});
    return d
  });

  var time = new Date().getTime();
  var signature = sha256(
    `${key}|${clientId}|${time}|${JSON.stringify(list)}`
  ).toString();
  var dataSend = {
    clientId: clientId,
    data: list,
    time,
    signature,
  };
  try {
    return Axios.post(`${url}/history/add`, dataSend);
  } catch (e) {
    return false;
  }
};

export const getHistory = (code = "", model = "", page = 1) => {
  var time = new Date().getTime();
  var d = {
    limit: 5,
    skip: (page - 1) * 5,
    from: moment().format("YYYY-01-01"),
    to: moment().format("YYYY-12-31"),
    code: code,
    model: model,
    label: "",
    companyCode: Vue.prototype.$company.companyCode,
  };

  // d = Object.keys(d)
  //   .sort()
  //   .reduce(function (acc, key) { 
  //       acc[key] = d[key];
  //       return acc;
  //   }, {});
  console.log(`${key}|${clientId}|${time}|${JSON.stringify(d)}`)
  var signature = sha256(
    `${key}|${clientId}|${time}|${JSON.stringify(d)}`
  ).toString();
  var dataSend = {
    clientId: clientId,
    data: d,
    time,
    signature,
  };

  return Axios.post(`${url}/history`, dataSend);
};
