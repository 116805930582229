<template>
  <div
    :class="isOpen ? ' open' : ''"
    class="quick-view"
    @click.self="closePopup()"
  >
    <div class="quick-view-content bg-white sm">
      <div class=" px-2 pt-2 ">
        <div class="flex flex-row align-items-center">
          <div class="flex flex-1 mr-2">
            <p class="m-0 " v-if="!isSingle">
              Đã chọn
              <strong class="font-16">{{ listSelecteds.length }}</strong> knhà cung cấp
            </p>
            <h5 class="m-0 no-break" v-if="isSingle">Chọn nhà cung cấp</h5>
          </div>
          <button
            @click="closePopup()"
            type="button"
            class="btn btn-basic pl-3 pr-3 mr-2"
          >
            {{ $t("common.cancel") }}
          </button>
          <button
            type="submit"
            v-if="!isSingle"
            class="btn btn-primary pl-3 pr-3"
            @click="save()"
          >
            <span class="d-none d-sm-inline-block text-white ml-1">
              {{ $t("common.done") }}
            </span>
            <i class="fa fa-arrow-right" />
          </button>
        </div>

        <div class="tabs-border flex flex-row align-items-stretch">
          <div
            class="tab"
            :class="{ active: tabActive == 'product' }"
            @click="tabActive = 'product'"
          >
            Nhà cung cấp
          </div>

          <div
            class="tab"
            :class="{ active: tabActive == 'selected' }"
            @click="tabActive = 'selected'"
          >
            Nhà cung cấp đã chọn ({{ listSelecteds.length }})
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div
          class="data-table"
          style="overflow: auto; height: calc(100lvh - 140px)"
        >
          <table class="table">
            <thead>
              <tr>
                <th v-if="!isSingle" style="width: 30px">
                  <check-box v-model="allCheck" @change="checkAll()" />
                </th>
                <th class="">Tên nhà cung cấp</th>
                <th class="">Địa chỉ</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="pointer"
                v-for="item in tabActive == 'product'
                  ? itemList
                  : listSelecteds"
                :key="item.id"
                :class="{ selected: isCheck(item) }"
              >
                <td v-if="!isSingle" @click="choose(item)">
                  <check-box :value="isCheck(item)" />
                </td>
                <td class="" @click="choose(item)">
                  <div class="m-0 bold">
                    {{ item.customerName }}
                  </div>
                  <p class="m-0 sub-text">SĐT: {{ item.phoneNumber }}</p>
                  <p class="m-0 sub-text">
                    {{ item.customerGroup ? item.customerGroup.groupName : "" }}
                  </p>
                </td>
                <td>
                  {{ item.address }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="loading" class="loading">
        <div>
          <div class="loading-spin">
            <i />
          </div>
          <p>{{ $t("common.loading") }}</p>
        </div>
      </div>
      <div class="bg-white flex flex-row align-items-center p-2 pr-4">
        <div class="flex flex-1">
          <Pagination
            ref="paging"
            :total="totalPage"
            :currentPage="page"
            @changePage="onChangePage"
          />
        </div>
        <div class="flex flex-md-300px">
          <input
            maxlength="200"
            class="form-control font-14"
            :placeholder="$t('common.search')"
            v-model="keySearch"
            @keyup.enter="search()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/api";
import Pagination from "@/components/Pagination";
export default {
  name: "SelectProduct",
  components: { Pagination },
  watch: {},
  data: function() {
    return {
      tabActive: "product",
      allCheck: true,
      isOpen: false,
      error: "",
      itemList: [],
      keySearch: "",
      limit: 25,
      page: 1,
      total: 0,
      totalPage: 1,
      loading: true,
      listSelecteds: [],
      isSingle: false,
      fields: [],
    };
  },
  methods: {
    checkAll() {
      if (this.allCheck) {
        this.itemList.map((product) => {
          var item = this.listSelecteds.find((i) => i.id == product.id);
          if (!item) {
            this.listSelecteds.push(product);
          }
        });
      } else {
        this.itemList.map((product) => {
          var item = this.listSelecteds.find((i) => i.id == product.id);
          if (item) {
            this.listSelecteds = this.listSelecteds.filter(
              (i) => i.id != product.id
            );
          }
        });
      }
      this.updateCheckAll();
    },
    updateCheckAll() {
      var allCheck = true;
      var isNone = true;
      this.itemList.map((product) => {
        var item = this.listSelecteds.find((i) => i.id == product.id);
        if (!item) {
          allCheck = 2;
        } else {
          isNone = false;
        }
      });
      this.allCheck = isNone ? false : allCheck;
    },
    isCheck(item) {
      return this.listSelecteds.find((i) => i.id == item.id);
    },
    onChangePage(data) {
      this.page = data;
      this.getData();
    },
    changeLimit() {
      this.getData();
    },
    search() {
      this.page = 1;
      this.getData();
    },
    getData(whereConfig = {}) {
      let where = {
        "companies.id": this.$companyId,
      };
      this.loading = true;
      if (this.keySearch) {
        where["OR"] = [
          { customerName_fulltext: this.keySearch },
          { phoneNumber_ilike: this.keySearch },
          { address_ilike: this.keySearch },
          { 'customerGroup.groupName_ilike': this.keySearch },
        ];
      }
      where = {
        ...where,
        ...whereConfig,
      };
      api
        .getSuppliers(
          where,
          this.limit,
          (this.page - 1) * this.limit,
          "id_desc",
          true
        )
        .then((res) => {
          this.loading = false;
          this.itemList = res.list.map((item) => {
            return item;
          });
          this.total = res.totalCount;
          this.totalPage = Math.ceil(this.total / this.limit);
          this.updateCheckAll();
        });
    },
    openPopup(where, config = {}) {
      if (config) {
        this.fields = config.fields || [];
      }
      this.isSingle = config.isSingle || false;
      this.isOpen = true;
      this.error = "";
      this.keySearch = "";
      this.getData(where);

      // this.forceWhere = where;
      this.listSelecteds = [];
      this.itemList = [];
      return new Promise((resolve, reject) => {
        this._save = resolve;
        this._close = reject;
      });
    },
    closePopup() {
      this.isOpen = false;
      this._close();
    },
    choose(product, isSelect) {
      if (this.isSingle) {
        this.isOpen = false;
        this._save(product);
        return;
      }
      const index = this.listSelecteds.map((i) => i.id).indexOf(product.id);
      if (index > -1) {
        if (!isSelect) {
          this.listSelecteds = this.listSelecteds.filter(
            (i) => i.id != product.id
          );
        }
      } else {
        this.listSelecteds.push(product);
      }
      this.updateCheckAll();
    },
    save() {
      this.isOpen = false;
      this._save(this.listSelecteds);
    },
  },
};
</script>
