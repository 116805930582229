import { render, staticRenderFns } from "./FilterSide.vue?vue&type=template&id=be6a88b6&"
import script from "./FilterSide.vue?vue&type=script&lang=js&"
export * from "./FilterSide.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports