<template>
  <div id="sidebar">
    <div
      :class="isOpen ? 'side-order active' : 'side-order'"
      @click.self="isOpen = false"
    >
      <div class="side-order-inner">
        <div v-outside="outside">
          <div class="change-branch" @click="isShowChange = !isShowChange">
            <i
              :class="`fa  menu-btn  fa-${!isOpen ? 'bars' : 'arrow-left '}`"
              style="z-index: 6"
              :style="{
                color:
                  ($route.path === '/401' || $route.path === '/404') && !isOpen
                    ? '#555'
                    : 'white',
              }"
              @click="isOpen = !isOpen"
            />
            <h5>
              {{ branchCurrent.branchName }} ({{ branchCurrent.branchCode }})
            </h5>
            <p>
              {{ branchCurrent.areas.areaName }} -
              {{ branchCurrent.companies.companyName }}
            </p>
            <button v-if="branches.length > 1">
              <i class="fa fa-chevron-down" />
            </button>
          </div>
          <div class="branch-list" :class="{ show: isShowChange }">
            <div
              v-for="bra in branches.filter((i) => i.id != branchId)"
              :key="bra.id"
              @click="changeBranch(bra)"
            >
              <h6>{{ bra.branchName }} ({{ bra.branchCode }})</h6>
              <p>{{ bra.areas.areaName }} - {{ bra.companies.companyName }}</p>
            </div>
          </div>
        </div>
        <div class="menus">
          <template v-for="(menu, idx) in menus">
            <div
              :key="menu.icon + idx"
              class="item"
              v-if="$permission(menu.permission)"
            >
              <div class="flex flex-row align-items-center">
                <router-link :to="menu.url" exact>
                  <img :src="`images/` + menu.icon + `.png`" />
                </router-link>
                <div class="flex flex-1">
                  <p class="p-1">{{ $t(menu.label) }}</p>
                  <div class="mb-0">
                    <template v-for="child in menu.children">
                      <router-link
                        exact
                        v-if="$permission(child.permission)"
                        class="child py-1 px-2 mr-2 mb-2"
                        :to="child.url"
                        :key="child.url"
                      >
                        {{ $t(child.label) }}
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div
          class="shift-sales-btn"
          v-if="$permission('ORDER:SHIFT')"
          @click="openShiftSalesPopup()"
        >
          <i class="fa fa-unlock font-24" /> Chốt ca
        </div>
        <div class="user">
          <div class="flex flex-row align-items-center">
            <router-link
              tag="img"
              to="/profile"
              class="img pointer"
              :style="{ 'background-image': `url(${user.avatar})` }"
            />
            <div class="flex-fill">
              <h4 class="mb-0">
                {{ user.userName }}
              </h4>
              <router-link
                class="pointer font-12 text-white mr-2"
                to="/profile"
              >
                Xem thông tin
              </router-link>
              <router-link
                class="text-white font-12"
                to="/profile?tab=password"
              >
                {{ $t("common.change_password") }}
              </router-link>
            </div>
            <div class="logout">
              <button @click="logout()">
                <i class="fa fa-sign-out-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 100lvh">
      <i
        :class="`fa  menu-btn  fa-${!isOpen ? 'bars' : 'arrow-left '}`"
        style="z-index: 6"
        :style="{
          color:
            ($route.path === '/401' || $route.path === '/404') && !isOpen
              ? '#555'
              : 'white',
        }"
        @click="isOpen = !isOpen"
      />
      <router-view></router-view>
    </div>
    <ShiftSalePopup ref="shiftSalePopup" />
  </div>
</template>

<script>
import * as api from "@/services/api";
import { resetData } from "@/services/common";
import ShiftSalePopup from "@/components/ShiftSalePopup";
import { MENU_SALE } from "@/config/BranchConfig";
export default {
  name: "Layout",
  components: {
    ShiftSalePopup,
  },
  created() {
    this.branchCurrent = JSON.parse(localStorage.getItem("branch")) || {};
    this.branchId = this.branchCurrent.id;
    this.user = JSON.parse(localStorage.getItem("user")) || {};
    this.user.avatar = this.branchCurrent.companies
      ? this.branchCurrent.companies.logoUrl
      : "";
    this.branches = JSON.parse(localStorage.getItem("branchList"));
    this.branches = this.branches.map((i) => i.branches);
    // this.getCurrentUser();
  },
  data: () => {
    return {
      isShowChange: false,
      branchCurrent: {},
      isOpen: false,
      user: {},
      branches: [],
      branchId: "",
      menus: MENU_SALE,
    };
  },
  methods: {
    async changeBranch(branch) {
      const confirmPopup = await this.$confirmPopup.open({
        title: this.$t("common.change_branch"),
        body:
          this.$t("common.change_branch_desc") +
          " <br><strong>" +
          branch.branchName +
          "</strong>",
      });
      if (confirmPopup) {
        await api.changeBranch(branch.id);
        this.$global.setLoading(true);
        await resetData();
        window.location.reload();
      }
    },
    logout() {
      this.$confirmPopup
        .open({
          title: this.$t("common.logout"),
          body: this.$t("common.logout_desc_modal"),
        })
        .then(() => {
          this.logoutRun();
        });
    },
    logoutRun() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("branch");
      localStorage.removeItem("currentRoles");
      this.$router.push("/login");
    },
    outside() {
      if (this.isShowChange) {
        this.isShowChange = false;
      }
    },
    openShiftSalesPopup() {
      this.$refs.shiftSalePopup.openPopup();
    },
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
};
</script>
