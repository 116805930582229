<template>
  <div class="switch-wrap" v-if="title">
    <div class="switch" @click="change()" :class="{ active: isOn }"></div>
    <p>{{ title }}</p>
  </div>
  <div class="switch" v-else @click="change()" :class="{ active: isOn }"></div>
</template>

<script>
export default {
  name: "switch-input",
  props: ["value", "title"],
  data: () => {
    return {
      isOn: false,
    };
  },
  mounted() {
    this.isOn = this.value;
  },
  watch: {
    value() {
      this.isOn = this.value;
    },
  },
  methods: {
    change() {
      this.isOn = !this.isOn;
      this.$emit("input", this.isOn);
      this.$emit("change", this.isOn);
    },
  },
};
</script> 