<template>
  <div :class="{ disabled: $props.disabled }" class="radio-swapper">
    <div
      v-for="item in list"
      :key="item.key"
      class="radio"
      @click="readOnly || disabled ? $emit('click') : check(item)"
      :class="{ active: value === item.key }"
    >
      <span class="radio-check" :class="{ active: value === item.key }" />
      <span class="radio-title">{{ item.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckBox",
  props: ["value", "disabled", "readOnly", "list"],
  methods: {
    check(item) {
      this.$emit("input", item.key);
      this.$emit("change", item);
    },
  },
};
</script>
