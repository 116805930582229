<template>
  <div
    class="filter-side"
    :class="{ show: isShow }"
    @click.self="isShow = false"
  >
    <div class="filter-inner">
      <div class="flex flex-row pl-3 align-items-center">
        <p class="m-0 font-16 bold flex flex-1">Lọc danh sách</p>
        <button class="btn btn-basic" @click="isShow = false">
          <i class="mdi mdi-close-circle font-20" />
        </button>
      </div>
      <div style="height: calc(100lvh - 100px); overflow: auto">
        <div
          v-for="c in config"
          :key="c.key"
          :style="{ flex: '0 0 ' + c.width }"
        >
          <label
            @click="
              c.noShow = !c.noShow;
              $forceUpdate();
            "
            class="pointer py-0 mt-2 d-flex align-items-center"
          >
            <span class="flex-fill">
              {{ c.name || "-" }}
            </span>
            <i class="mdi mdi-chevron-up" v-if="c.noShow" />
            <i class="mdi mdi-chevron-down" v-else />
          </label>
          <template v-if="!c.noShow">
            <div
              class="input-group p-1 pl-3 pr-3"
              v-if="c.type == 'number-range'"
            >
              <input
                class="form-control"
                placeholder="Từ"
                type="number"
                @change="
                  changeNumberRange(c.key, 0, $event);
                  $forceUpdate();
                "
                min="0"
              />
              <input
                class="form-control"
                placeholder="Đến"
                type="number"
                @change="
                  changeNumberRange(c.key, 1, $event);
                  $forceUpdate();
                "
                min="0"
              />
            </div>
            <input
              class="form-control"
              v-model="c.value"
              v-if="c.type == 'input'"
            />
            <div class="w100 pl-2 pr-2" v-else-if="c.type == 'date'">
              <date-picker class="w100" v-model="c.value" />
            </div>
            <div v-else-if="c.type == 'select'" class="drop">
              <div class="drop-selects">
                <p
                  class="drop-item pointer m-0 d-flex align-items-center"
                  @click="
                    c.value = '';
                    $forceUpdate();
                  "
                  :class="{ active: c.value == '' }"
                >
                  <i
                    v-if="c.value == ''"
                    class="
                      mdi mdi-checkbox-marked-circle
                      font-18
                      text-primary
                      mr-1
                    "
                  />
                  <i
                    v-else
                    class="
                      mdi mdi-checkbox-blank-circle-outline
                      mr-1
                      font-18
                      text-primary
                    "
                  />
                  {{ $t("common.all") }}
                </p>
              </div>
              <div class="drop-selects">
                <p
                  class="drop-item pointer m-0 d-flex align-items-center"
                  v-for="item in c.selectList"
                  @click="
                    c.value = item.key;
                    $forceUpdate();
                  "
                  :class="{ active: c.value == item.key }"
                  :key="item.key"
                >
                  <i
                    v-if="c.value == item.key"
                    class="
                      mdi mdi-checkbox-marked-circle
                      font-18
                      text-primary
                      mr-1
                    "
                  />
                  <i
                    v-else
                    class="
                      mdi mdi-checkbox-blank-circle-outline
                      mr-1
                      font-18
                      text-primary
                    "
                  />
                  {{ item.name || "-" }}
                </p>
              </div>
            </div>
            <div v-else-if="c.type == 'multi-select'" class="drop">
              <div class="drop-selects">
                <p
                  class="drop-item pointer m-0 d-flex align-items-center"
                  :class="{ active: item.isSelected }"
                  @click="
                    item.isSelected = !item.isSelected;
                    c.value = c.selectList
                      .filter((a) => a.isSelected)
                      .map((a) => a.key);
                    $forceUpdate();
                  "
                  v-for="item in c.selectList"
                  :key="item.key"
                >
                  <i
                    v-if="item.isSelected"
                    class="mdi mdi-checkbox-marked font-18 text-primary mr-1"
                  />
                  <i
                    v-else
                    class="
                      mdi mdi-checkbox-blank-outline
                      font-18
                      text-primary
                      mr-1
                    "
                  />
                  {{ item.name || "-" }}
                </p>
              </div>
            </div>
            <div class="w100 pl-3 pr-3" v-else-if="c.type == 'date-range'">
              <date-picker
                class="w100"
                v-model="c.value"
                range
                :placeholder="c.name"
                :shortcuts="$shortcutDateRange"
              />
            </div>
          </template>
        </div>
      </div>

      <div class="text-center p-2">
        <button
          class="btn btn-primary ml-2"
          @click="
            isShow = false;
            onChange();
          "
        >
          Áp dụng lọc
        </button>
        <a
          class="text text-danger ml-4"
          @click="
            isShow = false;
            cancel();
          "
          >Huỷ lọc</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FilterData",
  // props: ["change"],
  data() {
    return {
      isShow: false,
      config: {},
      showMenu: {},
    };
  },
  created() {},
  methods: {
    open(config, values = {}) {
      this.config = config;

      this.config.map((i) => {
        if (i.type == "select") {
          i.value = values[i.key] || "";
        } else if (i.type == "multi-select") {
          i.value = values[i.key] ? values[i.key].split(",") : [];
          i.selectList = i.selectList.map((a) => ({
            ...a,
            isSelected: i.value.indexOf(a.key) > -1,
          }));
        } else if (i.type == "number-range") {
          i.value = values[i.key] ? values[i.key].split(",") : [];
        } else {
          i.value = "";
        }
      });

      this.isShow = true;
      return new Promise((resolve, reject) => {
        this._ok = resolve;
        this._cancel = reject;
      });
    },
    onChange() {
      const out = {};
      this.config.map((i) => {
        out[i.key] = i.value;
      });

      this._ok(out);
      // this.$emit("change", out);
    },
    cancel() {
      this.config.map((i) => {
        if (i.type == "select") {
          i.value = "";
        } else if (i.type == "number-range") {
          i.value = [];
        } else {
          i.value = "";
        }
      });
      this._ok({});
      // this.$emit("change", {});
    },
    changeNumberRange(conf, index, e) {
      let value = e.target.value;
      let idx = this.config.findIndex((p) => p.key === conf);
      this.config[idx].value = this.config[idx].value || [];
      this.config[idx].value[index] = value;
      // this.onChange();
    },
  },
};
</script>
