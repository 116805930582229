const scrollMixin = {
    mounted() {
        this.onScrollTable();
        this.eventScroll = window.addEventListener('resize', () => {
            this.onScrollTable();
        });
    },
    data() {
        return {
            isScrollLeft: false,
            isScrollRight: false,
            eventScroll: null
        }
    },
    beforeUnmount() {
        removeEventListener(this.eventScroll);
    },
    methods: {
        onScrollTable() {
            var e = this.$refs.scrollTableHorizon;
            if (e && e.scrollLeft <= 0) {
                this.isScrollLeft = false;
            } else {
                this.isScrollLeft = true;
            }

            if (e && e.offsetWidth + e.scrollLeft >= e.scrollWidth) {
                this.isScrollRight = false;
            } else {
                this.isScrollRight = true;
            }
        },
    }
}


const scanCodeMixin = {
    methods: {
        scanCode(code) {
            print(code)
        }
    },
    created() {
        var code = '';
        var reading = false;
        this.functionEvent = (e) => {
            if (e.keyCode === 13) {
                if (code.length > 5) {
                    this.start = 0;
                    this.scanCode(code);
                    setTimeout(() => {
                        code = "";
                        this.productSearchKey = "";
                    }, 200);
                    code = "";
                    e.preventDefault();
                    return;
                }
            } else {
                code += e.key;
            }
            if (!reading) {
                reading = true;
                setTimeout(() => {
                    code = "";
                    reading = false;
                }, 200);
            }
        };

        document.addEventListener("keypress", this.functionEvent);
    },
    destroyed() {
        document.removeEventListener("keypress", this.functionEvent);
    },
}

export { scrollMixin, scanCodeMixin };