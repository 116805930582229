<template>
  <div id="app">
    <div v-if="done">
      <Layout />
      <div v-if="$global.isLoading" class="loading-global">
        <i class="fa fa-spinner fa-pulse fa-spin font-40 text-white" />
      </div>
    </div>
    <div
      v-else
      style="height: 100lvh"
      class="flex align-items-center justify-content-center"
    >
      <p>Tải nội dung: {{ percent }}%</p>
      <div class="box box-200 mt-1">
        <div
          class="flex flex-row"
          style="overflow: hidden; border-radius: 10px"
        >
          <div
            :class="
              'bg-main flex flex-' + $formatQuantity(percent, false, true)
            "
            style="height: 10px"
          />
          <div
            :class="
              'bg-white flex flex-' +
              $formatQuantity(100 - percent, false, true)
            "
            style="height: 10px"
          />
        </div>
      </div>

      <button class="btn btn-primary-outline mt-5" @click="logout()">
        Đăng nhập với tài khoản khác
      </button>
    </div>

    <PaymentOrderList ref="paymentOrderList" />
    <FilterSide ref="filterSide" />
    <SelectProduct ref="selectProduct" />
    <SelectStock ref="selectStock" />
    <SelectCustomer ref="selectCustomer" />
    <SelectEmployee ref="selectEmployee" />
    <SelectSupplier ref="selectSupplier" />

    <Notification />
    <FormPopup ref="formPopup" />
    <QuickView />
    <DetailQuickView ref="detail" />
    <ConfirmPopup />
  </div>
</template>

<script>
import Layout from "./components/Layout.vue";
import Vue from "vue";
import _ from "lodash";
import FormPopup from "./components/FormPopup.vue";
import DetailQuickView from "@/components/DetailQuickView";
import QuickView from "./components/QuickView.vue";
import ConfirmPopup from "./components/ConfirmPopup.vue";
import Notification from "./components/Notification.vue";
import MoneyInput from "./components/MoneyInput";
import QuantityInput from "./components/QuantityInput";
import CheckBox from "./components/CheckBox";
import Options from "./components/Options";
import DataTable from "./components/DataTable.vue";
import DataTableLocal from "./components/DataTableLocal.vue";
import Switch from "./components/Switch";
import Radio from "./components/Radio";
import Lines from "./components/Lines";
import AutoComplete from "./components/AutoComplete";

import VueBarcode from "vue-barcode";
import JsonExcel from "vue-json-excel";
import lodash from "lodash";

import { resetData } from "./services/common";

import * as api from "./services/api";
import axios from "axios";
import FilterSide from "./components/FilterSide.vue";
import SelectProduct from "./components/SelectProduct.vue";
import SelectCustomer from "./components/SelectCustomer.vue";
import SelectEmployee from "./components/SelectEmployee.vue";
import SelectSupplier from "./components/SelectSupplier.vue";
import SelectStock from "./components/SelectStock.vue";
import moment from "moment";
import PaymentOrderList from "./components/PaymentOrderList.vue";

const METRIC = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "K" },
];

Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype._ = lodash;
Vue.prototype.$global = Vue.observable({
  setLoading(value) {
    this.isLoading = !!value;
  },
  isLoading: false,
});
Vue.prototype.$copyObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

console.log(process.env);

Vue.prototype.$formatMoney = (num, notZero, decimal = 1, noPrefix) => {
  const currencyDecimal = Vue.prototype.$currencyDecimal || 1;
  num = Math.round(num * decimal) / decimal / currencyDecimal;
  if (notZero && (num == 0 || isNaN(num))) {
    return "-";
  }

  var out = num
    ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    : "0";
  if (noPrefix) {
    return out;
  }
  return out + "" + Vue.prototype.$currencySymbol;
};

Vue.prototype.$formatQuantity = (num, notZero, decimal = 100) => {
  num = Math.round(num * decimal) / decimal;
  if (notZero && (num == 0 || isNaN(num))) {
    return "-";
  }
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0";
  // (noPrefix ? "" : "₫")
};

String.prototype.insert = function (index, string) {
  if (index > 0) {
    return this.substring(0, index) + string + this.substr(index);
  }

  return string + this;
};
Vue.prototype.$unitFormat = (value, unitName) => {
  var units = Vue.prototype.$units;
  var u = units.find((a) => a.name == unitName);

  var out = Vue.prototype.$formatQuantity(value) + " " + unitName;

  if (u && u.transferUnits && u.transferUnits.length) {
    u.transferUnits
      .sort((a, b) => a.transferValue - b.transferValue)
      .map((tr) => {
        var v = Math.floor(value / tr.transferValue);
        if (v >= 1) {
          var s = Vue.prototype.$formatQuantity(v) + " " + tr.transferName;
          if (value % tr.transferValue == 0) {
            out += `<p class="sub-text">= ${s}</p>`;
          } else {
            var s2 =
              Vue.prototype.$formatQuantity(value % tr.transferValue) +
              " " +
              unitName;
            out += `<p class="sub-text">= ${s} ${s2}</p>`;
          }
        }
      });
  }

  return out;
};

Vue.prototype.$formatTime = (date) => {
  const d = date ? new Date(date) : new Date();
  return (
    ("00" + d.getHours()).slice(-2) + ":" + ("00" + d.getMinutes()).slice(-2)
  );
};
Vue.prototype.$toNumber = (str) => {
  return str ? str.match(/[0-9]+/g).join("") : "";
};

Vue.prototype.$getElementCode = (code) => {
  const elementSaleCode = Vue.prototype.$financeAuto.find(
    (a) => a.keyPayment == code
  )?.elementCode;
  const element =
    Vue.prototype.$financeCodes.find((a) => a.code == elementSaleCode) || {};
  return element;
};

Vue.prototype.$toDate = (str) => {
  return new Date(str);
};

Vue.prototype.$formatDate = (date) => {
  const d = date ? new Date(date) : new Date();
  return (
    d.getFullYear() +
    "-" +
    ("00" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + d.getDate()).slice(-2)
  );
};

Vue.prototype.$formatFullDate = (date) => {
  const d = date ? new Date(date) : new Date();
  return moment(d).format("YYYY-MM-DD | hh:mm");
};

Vue.prototype.$sum = (arr = [], key) => {
  let total = 0;
  arr.map((item) => {
    total += key ? item[key] : item;
  });
  return +total || 0;
};

Vue.prototype.$isMobile = () => {
  return window.innerWidth <= 768;
};
Vue.prototype.$getMetric = (number) => {
  for (var i = 0; i < METRIC.length; i++) {
    if (number >= METRIC[i].divider) {
      return (number / METRIC[i].divider).toString() + METRIC[i].suffix;
    }
  }
  return number.toString();
};

Vue.prototype.$makeid = (length = 6) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

Vue.prototype.$changeAlias = (alias) => {
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/ + /g, " ");
  str = str.trim();
  return str;
};

Vue.prototype.$back = () => {
  history.back();
};
Vue.prototype.$shortcutDateRangeFuture = [
  {
    text: "Tuần sau",
    onClick() {
      const curr = new Date();
      const first =
        curr.getDate() - curr.getDay() + (curr.getDay() == 0 ? -6 : 1);
      const last = first + 6;
      const start = new Date(curr.setDate(first + 7));
      const end = new Date(curr.setDate(last + 7));
      return [start, end];
    },
  },
  {
    text: "Tháng sau",
    onClick() {
      const date = new Date();
      const start = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      const end = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      return [start, end];
    },
  },
  {
    text: "Năm sau",
    onClick() {
      const date = new Date();
      const start = new Date(date.getFullYear() + 1, 0, 1);
      const end = new Date(date.getFullYear() + 2, 0, 0);
      return [start, end];
    },
  },
  {
    text: "Hôm nay tới cuối tuần",
    onClick() {
      const today = new Date();
      const curr = new Date();
      const first =
        curr.getDate() - curr.getDay() + (curr.getDay() == 0 ? -6 : 1);
      const last = first + 6;
      // const start = new Date(curr.setDate(first));
      const end = new Date(curr.setDate(last));
      return [today, end];
    },
  },
  {
    text: "Hôm nay tới cuối tháng",
    onClick() {
      const date = new Date();
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return [date, end];
    },
  },
  {
    text: "Hôm nay tới cuối năm",
    onClick() {
      const date = new Date();
      const end = new Date(date.getFullYear() + 1, 0, 0);
      return [date, end];
    },
  },
];
Vue.prototype.$allMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) => ({
  text: "Tháng " + m + "/" + moment().format("YYYY"),
  onClick() {
    return [
      moment("2022-" + m + "-01")
        .startOf("month")
        .toDate(),
      moment("2022-" + m + "-01")
        .endOf("month")
        .toDate(),
    ];
  },
}));

Vue.prototype.$clone = (a) => {
  return _.cloneDeep(a);
};

// Vue.prototype.$clone = (a) => {
//   const clone = (o) => {
//     if (o && typeof o == "object") {
//       if (Array.isArray(o)) {
//         return [...o.map((i) => clone(i))];
//       }
//       var out = {};
//       Object.keys(o).map((k) => {
//         out[k] = clone(o[k]);
//       });
//       return out;
//     }
//     return o;
//   };
//   return clone(a);
// };

Vue.prototype.$shortcutDateRange = [
  {
    text: "Hôm nay",
    onClick() {
      const curr = new Date();
      return [curr, curr];
    },
  },
  {
    text: "Tuần này",
    onClick() {
      const curr = new Date();
      const start = new Date(
        curr.setDate(
          curr.getDate() - curr.getDay() + (curr.getDay() == 0 ? -6 : 1)
        )
      );
      const end = new Date(curr.setDate(curr.getDate() + 5));
      return [start, end];
    },
  },
  {
    text: "Tháng này",
    onClick() {
      const date = new Date();
      const start = new Date(date.getFullYear(), date.getMonth(), 1);
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return [start, end];
    },
  },
  {
    text: "Năm nay",
    onClick() {
      const date = new Date();
      const start = new Date(date.getFullYear(), 0, 1);
      const end = new Date(date.getFullYear() + 1, 0, 0);
      return [start, end];
    },
  },
  {
    text: "Tuần trước",
    onClick() {
      const curr = new Date();
      const start = new Date(curr.setDate(curr.getDate() - curr.getDay()));
      const end = new Date(curr.setDate(curr.getDate() + 6));
      return [start, end];
    },
  },
  {
    text: "Tháng trước",
    onClick() {
      const date = new Date();
      const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      const end = new Date(date.getFullYear(), date.getMonth(), 0);
      return [start, end];
    },
  },
  {
    text: "Năm trước",
    onClick() {
      const curr = new Date();
      const start = new Date(curr.setFullYear(curr.getFullYear() - 1, 0, 1));
      const end = new Date(start.getFullYear(), 11, 31);
      return [start, end];
    },
  },
  {
    text: "Đầu tuần tới hôm nay",
    onClick() {
      const today = new Date();
      const curr = new Date();
      const first =
        curr.getDate() - curr.getDay() + (curr.getDay() == 0 ? -6 : 1);
      const start = new Date(curr.setDate(first));
      return [start, today];
    },
  },
  {
    text: "Đầu tháng tới hôm nay",
    onClick() {
      const date = new Date();
      const start = new Date(date.getFullYear(), date.getMonth(), 1);
      return [start, date];
    },
  },
  {
    text: "Đầu năm tới hôm nay",
    onClick() {
      const date = new Date();
      const start = new Date(date.getFullYear(), 0, 1);
      return [start, date];
    },
  },
];

Vue.prototype.$isInner = (str1, str2) => {
  const s1 = Vue.prototype.$changeAlias(str1 || "").toLowerCase();
  const s2 = Vue.prototype.$changeAlias(str2 || "").toLowerCase();
  if (!s2) {
    return true;
  }
  if (s1.indexOf(s2) > -1) {
    return true;
  } else {
    return false;
  }
};

String.prototype.highlight = function (s) {
  var r = new RegExp(s, "gi");
  return s ? (this || "").replace(r, (a) => `<mark>${a}</mark>`) : this;
};

Vue.prototype.$permission = (functions) => {
  if (!functions) {
    return false;
  }
  let currentRoles = localStorage.getItem("currentRoles");
  currentRoles = JSON.parse(currentRoles) || [];
  const functionList = functions.split(",");
  let out = false;
  functionList.map((fx) => {
    let isGroup = false;
    if (fx.indexOf(":") === -1) {
      isGroup = true;
    }
    currentRoles.map((item) => {
      if (isGroup) {
        if (item.fxCode && item.fxCode.indexOf(fx) === 0) {
          out = true;
        }
      } else {
        if (item.fxCode === fx) {
          out = true;
        }
      }
    });
  });
  return out;
};
Vue.prototype.$allLimit = [5, 10, 25, 50, 100, 1000];

Array.prototype.contains = function (v) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] === v) return true;
  }
  return false;
};

Array.prototype.search = function (key = "") {
  return this.filter(
    (a) => (a.name || "").toLowerCase().indexOf(key.toLowerCase()) > -1
  );
};

Array.prototype.unique = function () {
  var arr = [];
  for (var i = 0; i < this.length; i++) {
    if (!arr.contains(this[i])) {
      arr.push(this[i]);
    }
  }
  return arr;
};
Array.prototype.leftJoin = function (arr1, key, keep) {
  return this.filter(
    (s) => (keep && keep == s[key]) || !arr1.find((r) => r[key] == s[key])
  );
};

Array.prototype.isDuplicate = function () {
  return new Set(this).size !== this.length;
};
String.prototype.alias = function () {
  let str = this;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
  str = str.replace(/ + /g, " ");
  str = str.trim();
  return str;
};

Array.prototype.clone = function () {
  return JSON.parse(JSON.stringify(this));
};
Array.prototype.copy = function () {
  return JSON.parse(JSON.stringify(this));
};

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

String.prototype.next = function () {
  var str = this + "";
  var strs = str.split("");
  var newStrs = str.split("");

  var alphabet = "0123456789abcdefghijklmnopqrstuvwxyz".toUpperCase();
  // var alphabet = '0123456789'.toUpperCase();
  var next = (character) => {
    var current = alphabet.indexOf(character.toUpperCase());
    if (current == -1) {
      return character;
    }
    if (current >= alphabet.length - 1) {
      current = 0;
    } else {
      current = current + 1;
    }
    return alphabet[current];
  };
  var isStop = false;
  for (let i = strs.length - 1; i >= 0; i--) {
    if (!isStop) {
      newStrs[i] = next(strs[i]);
      isStop = newStrs[i] > strs[i] ? true : false;
    }
  }
  return newStrs.join("");
};
document.addEventListener("dragover", function (e) {
  e.preventDefault();
});

Array.prototype.switch = function (start, end) {
  var arr = this;
  var tmp = arr[start];
  arr[start] = arr[end];
  arr[end] = tmp;
  return arr;
};
Array.prototype.max = function () {
  var arr = this;
  if (!arr) {
    return null;
  }
  // var minV = arr[0];
  var maxV = arr[0];
  for (let a of arr) {
    // if (a < minV) minV = a;
    if (a > maxV) maxV = a;
  }
  return maxV;
};

Vue.prototype.$round = function (e) {
  return Math.round(e);
};

Vue.prototype.$round2 = function (e) {
  return Math.round(e * 100) / 100;
};

Array.prototype.mergeTotal = function (key, total) {
  var obj = {};
  var values = total.split(",");

  var _this = JSON.parse(JSON.stringify(this));

  for (var i = 0; i < _this.length; i++) {
    var item = _this[i];
    if (obj[item[key]]) {
      values.map((value) => {
        obj[item[key]][value] += item[value];
      });
    } else {
      var initObject = {
        ..._this[i],
      };
      values.map((value) => {
        initObject[value] = 0;
      });
      obj[item[key]] = initObject;

      values.map((value) => {
        obj[item[key]][value] += item[value];
      });
    }
  }
  return Object.keys(obj).map((i) => {
    return {
      ...obj[i],
    };
  });
};

Vue.prototype.$formatPercent = (num = 0) => {
  if (num == Infinity) {
    return "∞";
  }
  if (!num) {
    return "";
  }
  // if (num < 0) {
  //   num = num * -1;
  // }
  return Math.floor(num * 10000) / 100 + "%";
};

Vue.component("money-input", MoneyInput);
Vue.component("quantity-input", QuantityInput);
Vue.component("check-box", CheckBox);
Vue.component("data-table", DataTable);
Vue.component("data-table-local", DataTableLocal);
Vue.component("options", Options);
Vue.component("lines", Lines);
Vue.component("switch-input", Switch);
Vue.component("radio", Radio);
Vue.component("barcode", VueBarcode);
Vue.component("auto-complete", AutoComplete);
Vue.component("downloadExcel", JsonExcel);

Vue.directive("outside", {
  bind: function (el, binding) {
    const bubble = binding.modifiers.bubble;
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.__vueClickOutside__ = handler;
    document.addEventListener("click", handler);
  },
  unbind: function (el) {
    document.removeEventListener("click", el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  },
});

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("mousewheel", f);
      }
    };
    window.addEventListener("mousewheel", f);
  },
});

export default {
  name: "app",
  data() {
    return {
      done: false,
      percent: 1,
    };
  },
  methods: {
    appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("branch");
      localStorage.removeItem("saleOrders");
      localStorage.removeItem("currentRoles");
      // this.$router.push("/login");
      location.reload();
    },
  },
  mounted: async function () {
    window.addEventListener("resize", this.appHeight);
    this.appHeight();
    if (localStorage.getItem("token")) {
      try {
        await resetData(
          "",
          (percent) => (this.percent = Math.floor((100 / 15) * percent))
        );
        this.done = true;
      } catch (e) {
        this.done = true;
      }
    } else {
      this.done = true;
    }

    this.$root.filterSide = this.$refs.filterSide;
    this.$root.selectProduct = this.$refs.selectProduct;
    this.$root.selectStock = this.$refs.selectStock;
    this.$root.selectCustomer = this.$refs.selectCustomer;
    this.$root.selectSupplier = this.$refs.selectSupplier;
    this.$root.selectEmployee = this.$refs.selectEmployee;
    this.$root.detail = this.$refs.detail;
    this.$root.formPopup = this.$refs.formPopup;
    this.$root.paymentOrderList = this.$refs.paymentOrderList;
  },
  components: {
    Layout,
    ConfirmPopup,
    Notification,
    FormPopup,
    QuickView,
    FilterSide,
    SelectProduct,
    SelectCustomer,
    DetailQuickView,
    SelectEmployee,
    SelectSupplier,
    SelectStock,
    PaymentOrderList,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
