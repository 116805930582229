<template>
  <div class="noti-popup">
    <div
      class="noti"
      :class="{ 'noti-error': n.error }"
      v-for="n in $noti.list"
      :key="n.key"
    >
      <div class="flex flex-row align-items-center">
        <i v-if="n.error" class="mdi mdi-emoticon-sad font-25" />
        <i v-else class="mdi mdi-checkbox-marked-outline font-25" />
        <div class="flex flex-1 ml-2">
          <p class="font-14 bold m-0">
            {{
              n.title
                ? n.title
                : n.error
                ? $t("common.error_message")
                : $t("common.successfully")
            }}
          </p>
        </div>
        <i class="mdi mdi-close font-20 pointer" @click="$noti.close(n.key)" />
      </div>
      <p class="font-12 mb-0" v-if="n.text" v-html="n.text"></p>
      <a v-if="n.dataCopy" class="font-10" @click="$noti.copy(n.dataCopy)">
        Sao chép lỗi
      </a>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

const noti = Vue.observable({
  list: [],
  copy(txt) {
    copyToClipboard(txt);
  },
  open(data, error) {
    var notiPopup = {};
    notiPopup.key = new Date().getTime();
    if (typeof data === "string") {
      notiPopup.text = data;
      notiPopup.error = error || false;
    } else {
      if (data) {
        notiPopup.text = data.text;
        notiPopup.title = data.title;
        notiPopup.dataCopy = data.dataCopy;
        notiPopup.error = data.error || false;
      }
    }
    this.list = [notiPopup].concat(this.list);
    this.timeout = setTimeout(() => {
      this.list.pop();
    }, data.time || 4000);
    return new Promise((resolve, reject) => {
      this._ok = resolve;
      this._close = reject;
    });
  },
  ok() {
    this._ok(true);
    return true;
  },
  close(key) {
    this.list = this.list.filter((i) => i.key != key);
    return false;
  },
});
Vue.prototype.$noti = noti;
export default {
  name: "notiPopup",
};
</script>
 