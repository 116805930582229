<template>
  <div
    class="form-popup flex flex-row align-items-center"
    :class="{ show: isShow }"
    @click.self="config.allowClose ? closePopup() : null"
  >
    <div :class="'form-popup-content ' + config.size" v-if="isShow">
      <div class="form">
        <div
          class="form-popup-header px-2 pt-3"
          :class="haveFinance ? 'pb-0' : 'pb-3'"
        >
          <h4 class="m-0" v-html="config.title || 'Thêm mới'"></h4>
        </div>
        <FormData
          v-show="tabActive == 'form'"
          :isPopup="true"
          :inputs="inputs"
          :isSending="sending"
          :isWarning="warning"
          @onSubmit="onSubmit"
          @onChange="onChange"
          @onClose="closePopup()"
          ref="formData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormData from "./FormData.vue";

export default {
  components: { FormData },
  name: "FormPopup",
  data: function () {
    return {
      config: {},
      inputs: [],
      sending: false,
      warning: false,
      isShow: false,
      haveFinance: false,

      tabActive: "form",
      financeObj: {},
    };
  },

  watch: {
    "$route.path"() {
      this.closePopup();
    },
  },

  methods: {
    onChange(e) {
    },
    openPopup(inputs, config = {}, cb) {
      this.sending = false;
      this.output = {};
      this.inputs = inputs;
      this.config = config;
      this.isShow = true;
      this.haveFinance = false;
      this.tabActive = "form";
      if (config.finance) {
        this.financeObj = config.finance;
        this.haveFinance = true;
      }
      this._ok = cb;
      this.functionListener = (e) => {
        if (e.key === "Escape") {
          this.closePopup();
        }
      };
      document.addEventListener("keydown", this.functionListener);
    },
    setData(key, value) {
      this.$refs.formData.setData(key, value);
    },
    setAttr(key, type, value) {
      this.$refs.formData.setAttr(key, type, value);
    },
    closePopup() {
      this.isShow = false;
      document.addEventListener("keydown", this.functionListener);
    },
    async onSubmit(e) {
      this._ok(e);
    },
  },
};
</script>
