<template>
  <input
    v-model="text"
    :required="$props.required"
    @keyup="onChange()"
    @keyup.enter="$emit('onEnter')"
    @change="change()"
    @focus="onFocus"
    @click="onFocus"
    :autofocus="autofocus"
  />
</template>
<script>
export default {
  name: "QuantityInput",
  props: ["value", "required", "max", "min", "autofocus", "decimal"],
  data: () => {
    return {
      text: "",
    };
  },
  mounted() {
    const d = this.decimal || 100;
    this.text = Math.round((this.value || 0) * d) / d;
  },
  watch: {
    value() {
      const d = this.decimal || 100;
      this.text = Math.round((this.value || 0) * d) / d;
    },
  },
  methods: {
    change() {
      this.$emit("change");
    },
    onFocus(e) {
      e.target.select();
    },
    focus() {},
    onChangeBefore() {
      setTimeout(() => {
        this.onChange();
      }, 1000);
    },
    onChange() {
      this.text = this.text + "";
      var haveDot = false;
      this.text = this.text
        .replace(/[^0123456789.]/g, "")
        .split("")
        .filter((i) => {
          if (i !== ".") {
            return i;
          } else {
            if (haveDot) {
              return "";
            } else {
              haveDot = true;
              return i;
            }
          }
        })
        .join("");
      if (this.max && !isNaN(this.max) && this.text > this.max) {
        this.text = this.max;
      }
      this.text = this.text + "";
      this.$emit("input", this.text * 1);
      this.$emit("keyup");
      this.$emit("change");
    },
  },
};
</script>
