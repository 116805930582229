import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

var init = {
  dateFilter: [new Date(), new Date()],
};
if (localStorage.getItem('state')) {
  init = JSON.parse(localStorage.getItem('state'))
}

const store = new Vuex.Store({
  state: init,
  mutations: {
    setDateFiler(state, newData) {
      state.dateFilter = newData;
    },
  },
});


export default store;
