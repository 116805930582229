<template>
  <div
    class="confirm-popup flex flex-row align-items-center"
    v-if="$confirmPopup.isShow"
    @click.self="$confirmPopup.close()"
  >
    <div class="confim-content flex">
      <div class="confirm-popup-header">
        {{
          $confirmPopup.title ? $confirmPopup.title : $t("common.confirm_title")
        }}
        <i
          class="fa fa-times-circle pull-right font-18 pointer"
          @click="$confirmPopup.close()"
        />
      </div>
      <div class="confirm-popup-content">
        <p
          v-html="
            $confirmPopup.body ? $confirmPopup.body : $t('common.are_you_sure')
          "
        />
      </div>
      <div class="flex flex-row confirm-popup-footer">
        <button
          v-if="$confirmPopup.leftRun"
          @click="$confirmPopup.leftRun()"
          v-html="
            $confirmPopup.leftText ? $confirmPopup.leftText : $t('common.print')
          "
        />
        <div class="flex flex-1" />
        <button
          :disabled="$confirmPopup.sending"
          class="btn pl-4 pr-4"
          @click="$confirmPopup.close()"
        >
          <i
            v-if="$confirmPopup.sending"
            class="fa fa-spinner fa-pulse fa-spin"
          />
          {{
            $confirmPopup.closeText
              ? $confirmPopup.closeText
              : $t("common.close")
          }}
        </button>

        <button
          :disabled="$confirmPopup.sending"
          class="btn pl-4 pr-4 ml-2"
          :class="{
            'btn-danger': $confirmPopup.warning,
            'btn-primary': !$confirmPopup.warning,
          }"
          @click="$confirmPopup.ok()"
        >
          <i
            v-if="$confirmPopup.sending"
            class="fa fa-spinner fa-pulse fa-spin"
          />
          {{ $confirmPopup.okText ? $confirmPopup.okText : $t("common.ok") }}
        </button>
      </div>
    </div>
  </div>
</template>



<script>
import Vue from "vue";

const confirmPopup = Vue.observable({
  isShow: false,
  title: "",
  body: "",
  okText: "",
  warning: false,
  sending: false,
  openPopup(data, type) {
    this.open(data, type);
  },
  open(data, type) {
    if (data) {
      if (typeof data === "string") {
        this.title = "";
        this.okText = "";
        this.leftRun = null;
        this.leftText = "";

        this.body = data;
        this.warning = type;
      } else {
        this.title = data.title;
        this.body = data.body;
        this.okText = data.okText;
        this.warning = data.warning;
        this.leftRun = data.leftRun;
        this.leftText = data.leftText;
      }
    } else {
      this.title = "";
      this.body = "";
      this.okText = "";
      this.warning = false;
      this.leftRun = null;
      this.leftText = "";
    }
    confirmPopup.isShow = true;

    this.functionEvent = (e) => {
      if (e.key === "Enter") {
        this.ok();
      }
      if (e.key === "q" || e.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keydown", this.functionEvent);

    this.promise = new Promise((resolve, reject) => {
      this._ok = resolve;
      this._close = reject;
    });
    return this.promise;
  },
  ok() {
    confirmPopup.isShow = false;
    this._ok(true);
    document.removeEventListener("keydown", this.functionEvent);
    return true;
  },
  close() {
    this._close(false);
    confirmPopup.isShow = false;
    document.removeEventListener("keydown", this.functionEvent);
    // return false;
  },
});
Vue.prototype.$confirmPopup = confirmPopup;

export default {
  name: "ConfirmPopup",
};
</script>
 