<template>
  <div class="position-relative">
    <div class="d-flex mb-1">
      <quantity-input
        class="form-control text-right flex-1 font-14 box-80 mr-1"
        v-model="value"
        @input="
          (e) => {
            $emit('change', {
              quantity: e * transfer,
              unit: unitSelect,
            });
          }
        "
        :max="max"
        :decimal="decimal"
      />
      <select
        class="form-control w-70px"
        v-model="unitSelect"
        @change="onChange()"
        style="padding-left: 4px;"
        v-if="exchangeUnits && exchangeUnits.length > 0"
      >
        <option v-for="t in exchangeUnits" :key="t.name" :value="t.name">
          {{ t.name }}

          {{
            baseUnit !== t.name
              ? "(" + $formatQuantity(t.transfer * value) + " " + baseUnit + ")"
              : ""
          }}
        </option>
      </select>
    </div>
    <p
      class="sub-text m-0 position-absolute"
      style="font-size: 11px; right: 0; top: 32px"
    >
      {{ $formatQuantity(transfer * value) }}
      {{ baseUnit }}
    </p>
  </div>
</template>
<script>
import QuantityInput from "./QuantityInput.vue";

export default {
  name: "QuantityUnitInput",
  components: {
    QuantityInput,
  },
  props: ["exchangeUnits", "quantity", "unit", "decimal", "max"],
  data() {
    return {
      value: this.quantity,
      transfer: 1,
      unitSelect: this.unit,
    };
  },
  mounted() {
    const exchangeUnits = this.exchangeUnits || [];
    const s =
      exchangeUnits.find((t) => t.name == this.unitSelect) ||
      exchangeUnits.find((t) => t.transfer == 1);
    this.transfer = s ? s.transfer : 1;
    this.value = this.quantity / this.transfer;
    this.unitSelect = this.unit || this.baseUnit;
  },
  computed: {
    baseUnit() {
      const exchangeUnits = this.exchangeUnits || [];
      const s = exchangeUnits.find((t) => t.transfer == 1);
      return s ? s.name : "";
    },
  },
  methods: {
    onChange() {
      const exchangeUnits = this.exchangeUnits || [];
      const s =
        exchangeUnits.find((t) => t.name == this.unitSelect) ||
        exchangeUnits.find((t) => t.transfer == 1);
      this.transfer = s ? s.transfer : 1;

      this.$emit("change", {
        quantity: this.value * this.transfer,
        unit: this.unitSelect,
      });
    },
  },
};
</script>
