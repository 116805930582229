<template>
  <div class="popup-wrap" :class="{ show: isOpen }" @click.self="close()">
    <div class="popup">
      <div class="popup-header p-3 text-center">
        <h3 class="font-16 bold m-0">Thông tin cột</h3>
      </div>
      <div class="popup-body px-2">
        <div class="box">
          <div class="box-header d-flex px-2">
            <label class="w-40px text-center">Ví trí</label>
            <label class="flex-fill">Tên cột</label>
            <label class="w-100px text-right">Chiều rộng cột</label>
            <label class="w-60px text-center">Ẩn / Hiện</label>
          </div>
          <div style="overflow: auto; max-height: calc(100lvh - 200px)">
            <div
              v-for="column in columns"
              :key="column.key"
              draggable
              class="box-item p-0"
              @dragenter="stepOver = true"
              @dragover.prevent="stepOver = column.key"
              @dragleave.prevent="stepOver = column.key"
              @drop="onDropItem($event, column)"
              :style="{
                border:
                  stepOver == column.key
                    ? '2px dashed green'
                    : '2px solid transparent',
              }"
            >
              <div
                class="d-flex align-items-center p-2"
                :class="{ box: idDrag == column.key }"
                :draggable="false"
                @dragstart="
                  idDrag = column.key;
                  itemDrag = column;
                "
                @dragend="idDrag = ''"
              >
                <div class="text-center w-40px">
                  <p class="bold font-18">
                    {{ sorts[column.key] }}
                  </p>
                </div>
                <div class="flex-fill">
                  <p class="m-0">
                    {{ column.title }}
                  </p>
                  <p class="sub-text">{{ column.key }}</p>
                </div>
                <div class="w-150px">
                  <input
                    class="form-control form-control-sm text-right"
                    placeholder="Chiều rộng: px hoặc %"
                    v-model="widths[column.key]"
                  />
                </div>
                <div class="text-center w-60px">
                  <check-box
                    v-model="views[column.key]"
                    @input="$forceUpdate()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-footer p-2 text-right">
        <button class="btn btn-basic pl-4 pr-4 mr-2" @click="close()">
          Đóng
        </button>

        <button
          class="btn btn-outline-primary pl-4 pr-4 mr-2"
          @click="makeDefault()"
        >
          Mặc định
        </button>

        <button class="btn btn-primary pl-4 pr-4 mr-2" @click="save()">
          <i class="fa fa-save" />
          Lưu lại
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      columns: [],
      idDrag: "",
      stepOver: "",
      itemDrag: {},
      widths: {},
      views: {},
      sorts: {},
    };
  },
  props: ["tableName"],
  methods: {
    save() {
      const config = {
        order: this.columns.map((a) => a.key),
        views: this.views,
        widths: this.widths,
      };
      localStorage.setItem("config-" + this.tableName, JSON.stringify(config));
      this._ok();
      this.isOpen = false;
    },
    makeDefault() {
      this.columns.forEach((a, index) => {
        this.views[a.key] = true;
        this.widths[a.key] = a.width;
        this.sorts[a.key] = index + 1;
      });
    },
    onDropItem(event, newStep) {
      const tmp = newStep.sort;
      newStep.sort = this.itemDrag.sort;
      this.itemDrag.sort = tmp;
      this.idDrag = "";
      this.stepOver = "";
      this.stepChange = null;
      this.$forceUpdate();
    },
    open(config) {
      this.isOpen = true;
      this.columns = config.columns;
      this.columns.forEach((a, index) => {
        this.views[a.key] = true;
        this.widths[a.key] = a.width;
        this.sorts[a.key] = index + 1;
      });

      const d = localStorage.getItem("config-" + this.tableName);
      if (d) {
        const data = JSON.parse(d);
        this.views = data.views || this.views;
        this.widths = data.widths || this.widths;
        this.sorts = data.sorts || this.sorts;
      }
      this.$forceUpdate();
      return new Promise((resolve) => {
        this._ok = resolve;
      });
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
