<template>
  <input
    type="text"
    v-model="text"
    :required="$props.required"
    @keyup="onChange()"
    @keyup.enter="$emit('onEnter')"
    @change="$emit('change')"
    @focus="onFocus"
    @blur="onBlur"
    @click="onFocus"
    :autofocus="autofocus"
  />
</template>
<script>
export default {
  name: "MoneyInput",
  props: ["value", "required", "max", "min", "allowZero", "autofocus"],
  data: () => {
    return {
      text: "",
    };
  },
  mounted() {
    this.text = this.$formatMoney(this.value, false, 1, true);
  },
  watch: {
    value() {
      this.text = this.$formatMoney(this.value, false, 1, true);
    },
  },
  methods: {
    onFocus(e) {
      e.target.select();
      this.$emit("focus");
    },
    onBlur(e) {
      this.$emit("blur");
    },
    focus() {},
    convert(s) {
      const n = s.replace(/[^.\d]/g, "").match(/^\d+\.?\d*/);
      return n ? n[0] : "";
    },
    onChange() {
      let number = this.convert(this.text);
      if (number === "") {
        this.$emit("input", null);
        this.$emit("keyup");
        this.$emit("change");
        return;
      }
      if ((this.max || this.allowZero) && number > this.max) {
        number = this.max;
      }
      if ((this.min || this.allowZero) && number < this.min) {
        number = this.min;
      }
      number = number * this.$currencyDecimal;
      if (this.text[this.text.length - 1] === ".") {
        this.text = this.$formatMoney(number, false, 1, true) + ".";
      } else {
        this.text = this.$formatMoney(number, false, 1, true);
      }
      this.text.replace("..", ".");
      this.$emit("input", Number(number));
      this.$emit("keyup");
      this.$emit("change");
    },
  },
};
</script>
