<template>
  <span
    :class="{ disabled: $props.disabled }"
    class="check-box-swapper"
    @click="readOnly ? $emit('click') : check()"
  >
    <span
      v-if="titleLeft && title"
      class="check-box-title"
      :class="{ [className]: true }"
    >
      {{ title }}
    </span>
    <span class="check-box" :class="{ active: value }">
      <i class="fa fa-minus" v-if="value == 2" />
      <i class="fa fa-check" v-else />
    </span>
    <span
      v-if="!titleLeft && title"
      class="check-box-title"
      :class="{ [className]: true }"
    >
      {{ title }}
    </span>
  </span>
</template>
<script>
export default {
  name: "CheckBox",
  props: ["value", "title", "disabled", "titleLeft", "readOnly", "className"],
  methods: {
    check() {
      this.$emit("input", !this.value);
      this.$emit("change", !this.value);
    },
  },
};
</script>
