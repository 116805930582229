<template>
  <div
    :class="isOpen ? ' open' : ''"
    class="quick-view"
    @click.self="closePopup()"
  >
    <div
      class="quick-view-content sm"
      :style="{ width: $isMobile() ? '100%' : 'calc(100vw - 500px)' }"
    >
      <div class="bg-white pt-2 px-2 shadow">
        <div class="flex flex-row align-items-center">
          <div class="flex flex-1 mr-2">
            <h5 class="m-0 no-break font-16 bold">Chọn kho</h5>
          </div>

          <button
            @click="closePopup()"
            type="button"
            class="btn btn-basic pl-3 pr-3 mr-2"
          >
            {{ $t("common.cancel") }}
          </button>
          <button
            type="submit"
            class="btn btn-primary pl-3 pr-3"
            @click="save()"
          >
            <span class="d-none d-sm-inline-block text-white ml-1">
              {{ $t("common.done") }}
            </span>
            <i class="fa fa-arrow-right" />
          </button>
        </div>

        <div class="tabs-border flex flex-row mt-1">
          <div
            @click="tabActive = 'info'"
            :class="{ active: tabActive == 'info' }"
            class="tab"
          >
            Thông tin kho
          </div>

          <div
            @click="tabActive = 'finance'"
            :class="{ active: tabActive == 'finance' }"
            class="tab"
          >
            Hạch toán
          </div>
        </div>
      </div>
      <div v-if="tabActive == 'finance'" class="p-3">
        <div class="box box-700 mx-auto">
          <form-finance v-model="financeData" />
        </div>
      </div>
      <div
        class="p-2"
        style="height: calc(100lvh - 52px); overflow: auto"
        v-if="tabActive == 'info'"
      >
        <div class="data-table align-top p-0" style="min-height: 100%">
          <table class="table table-border mb-0">
            <thead>
              <tr>
                <!-- <th></th> -->
                <th style="width: 100%">Thông tin sản phẩm</th>
                <th style="width: 150px; min-width: 150px">Kho</th>
                <th style="width: 150px; min-width: 150px">Lô hàng</th>
                <th style="width: 100px; min-width: 100px">Số lượng</th>
                <th style="width: 30px; min-width: 30px"></th>
              </tr>
            </thead>
            <tbody
              :key="item.productId + '' + item.isGift"
              v-for="item in products.filter((a) => !a.isDelete)"
            >
              <template
                v-for="p in item.isBundle
                  ? [item].concat(item.children)
                  : [item]"
              >
                <tr
                  :key="p.productId + '' + l.stockId + '' + p.isGift"
                  v-for="(l, lIdx) in p.fulfills"
                  v-show="!p.bundleId || (p.bundleId && !item.noShowBundle)"
                >
                  <!-- <td v-if="!lIdx" :rowspan="p.fulfills.length">
                    <i
                      v-if="!p.bundleId"
                      @click="
                        item.isDelete = true;
                        countPrice();
                      "
                      class="
                        mdi mdi-trash-can-outline
                        text-danger
                        pointer
                        font-20
                      "
                    />
                  </td> -->
                  <td v-if="!lIdx" :rowspan="p.fulfills.length">
                    <div class="flex flex-row align-items-center">
                      <div
                        v-if="p.bundleId"
                        class="text-center font-20 bold pr-2 text-secondary"
                        style="min-width: 50px"
                      >
                        {{ p.bundleQuantity }}x
                      </div>
                      <div>
                        <p class="m-0 d-flex align-items-center">
                          <span class="mr-2 bold">
                            {{ p.name }}
                          </span>
                          <span
                            v-if="p.isBundle"
                            @click="
                              item.noShowBundle = !item.noShowBundle;
                              $forceUpdate();
                            "
                            class="tag tag-sm tag-secondary-outline pointer"
                          >
                            Combo
                            <i
                              class="fa fa-chevron-down"
                              v-if="item.noShowBundle"
                            />
                            <i class="fa fa-chevron-up" v-else />
                          </span>
                          <span
                            v-if="p.noCheckWarehouse"
                            class="tag tag-sm tag-primary-outline"
                          >
                            Dịch vụ
                          </span>
                          <span
                            v-if="p.saleOver"
                            class="tag tag-sm tag-warning-outline"
                          >
                            Bán quá SL
                          </span>

                          <span
                            v-if="p.isGift"
                            class="tag tag-sm tag-success-outline no-break"
                          >
                            <i class="fa fa-gift" />
                            Quà tăng
                          </span>
                        </p>
                        <p class="mb-2 sub-text">#{{ p.code }}</p>
                        <div
                          class="d-flex align-items-center"
                          v-if="!p.bundleId"
                        >
                          Cần: {{ item.remain }} ({{ p.unit.name || p.unit }})
                        </div>
                        <p v-else>
                          Cần: {{ item.remain * p.bundleQuantity }} ({{
                            p.unit.name || p.unit
                          }})
                        </p>
                        <div v-if="!disabled">
                          <span
                            class="status mt-2"
                            :class="
                              p.saleOver ? 'status-warning' : 'status-danger'
                            "
                            v-if="
                              p.remain - p.availableQty > 0 &&
                              !p.isBundle &&
                              !p.noCheckWarehouse &&
                              $branch.isStockCompany
                            "
                          >
                            Thiếu hàng ({{ p.remain - p.availableQty }})
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    colspan="4"
                    v-if="p.outOfStock"
                    class="text-center font-24 bold text-danger"
                    style="vertical-align: middle; opacity: 0.5"
                  >
                    HẾT HÀNG
                  </td>
                  <template v-else>
                    <td>
                      <template v-if="p.isBundle || p.noCheckWarehouse">
                        -
                      </template>
                      <template v-else>
                        <select
                          :disabled="disabled"
                          class="form-control mb-2"
                          v-model="l.stockId"
                          @change="onChangeStock(item, p, l)"
                        >
                          <option
                            v-for="s in p.stocks.leftJoin(
                              p.fulfills,
                              'stockId',
                              l.stockId
                            )"
                            :value="s.stockId"
                            :key="s.id"
                          >
                            {{ s.stockName }} ({{ s.availableQty }})
                          </option>
                        </select>
                        <a
                          class="text-primary fw-400 font-12"
                          v-if="
                            p.fulfills.length - 1 == lIdx &&
                            p.fulfills.length < p.stocks.length &&
                            !disabled
                          "
                          @click="addNewStock(p)"
                        >
                          <i class="fa fa-plus" /> Kho
                        </a>
                      </template>
                    </td>
                    <td>
                      <template v-if="p.isBundle || p.noCheckWarehouse">
                        -
                      </template>
                      <template v-else>
                        <div
                          v-for="b in l.batchNames"
                          :key="b.id"
                          class="mb-2 d-flex align-items-center"
                        >
                          <i
                            v-if="b.manufacturingDate || b.expiryDate"
                            class="fa fa-info-circle font-20 mr-1"
                            v-b-tooltip.hover.html="
                              `<span class='font-12 text-white'>  ${
                                b.manufacturingDate
                                  ? 'NSX: ' + b.manufacturingDate
                                  : ''
                              } <br /> ${
                                b.expiryDate ? 'HSD: ' + b.expiryDate : ''
                              } </span>`
                            "
                          />
                          <select
                            :disabled="disabled"
                            class="form-control"
                            v-model="b.batchName"
                            @change="countPrice()"
                          >
                            <option
                              v-for="s in l.allBatchNames.leftJoin(
                                l.batchNames,
                                'batchName',
                                b.batchName
                              )"
                              :key="s.id"
                              :value="s.batchName"
                            >
                              {{
                                s.batchName == "-"
                                  ? "Lô không tên"
                                  : s.batchName
                              }}
                              ({{ s.availableQty }})
                            </option>
                          </select>
                        </div>
                        <a
                          v-if="l.batchNames < l.allBatchNames && !disabled"
                          class="text-primary font-12"
                          @click="addNewBatchName(p, l)"
                        >
                          <i class="fa fa-plus" /> Lô hàng
                        </a>
                      </template>
                    </td>
                    <td colspan="2" v-if="p.isBundle || p.noCheckWarehouse">
                      <quantity-input
                        :disabled="true"
                        @change="countPrice()"
                        class="form-control text-right"
                        v-model="p.quantity"
                        :max="p.remain"
                      />
                    </td>
                    <template v-else>
                      <td
                        :colspan="
                          p.fulfills.reduce(
                            (a, b) => a + +b.batchNames.length,
                            0
                          ) > 1 && !disabled
                            ? 1
                            : 2
                        "
                      >
                        <div
                          v-for="b in l.batchNames"
                          class="mb-2"
                          :key="b.batchName"
                        >
                          <quantity-input
                            :disabled="disabled"
                            :max="!p.saleOver ? b.availableQty : undefined"
                            @change="countPrice()"
                            class="form-control text-right"
                            :class="{ error: p.missStock || p.overStock }"
                            v-model="b.quantity"
                          />
                        </div>
                      </td>
                      <td
                        v-if="
                          p.fulfills.reduce(
                            (a, b) => a + +b.batchNames.length,
                            0
                          ) > 1 && !disabled
                        "
                      >
                        <div
                          style="height: 35px"
                          class="mb-2"
                          v-for="b in l.batchNames"
                          :key="b.batchName"
                        >
                          <i
                            v-show="
                              p.fulfills.reduce(
                                (a, b) => a + +b.batchNames.length,
                                0
                              ) > 1
                            "
                            class="mdi mdi-close-circle-outline font-20 text-danger pointer"
                            @click="
                              l.batchNames = l.batchNames.filter(
                                (i) => i.batchName != b.batchName
                              );
                              countPrice();
                            "
                          />
                        </div>
                      </td>
                    </template>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="loading" class="loading">
        <div>
          <div class="loading-spin">
            <i />
          </div>
          <p>{{ $t("common.loading") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setStockAuto } from "../pages/order/service";
import FormFinance from "./FormFinance.vue";
export default {
  name: "SelectProduct",
  components: { FormFinance },
  data: function () {
    return {
      products: [],
      loading: false,
      isOpen: false,
      disabled: false,
      financeData: {},
      tabActive: "info",
    };
  },
  methods: {
    openPopup({ productList, financeData }, cb) {
      this.products = JSON.parse(JSON.stringify(productList));
      this.financeData = JSON.parse(JSON.stringify(financeData));
      this.products = setStockAuto(this.products);
      this.isOpen = true;
      this.loading = false;
      this._save = cb;
    },
    onChangeStock(product, child, stock) {
      var bs = child.batchNames.filter((i) => i.stockId == stock.stockId);
      
      stock.allBatchNames = bs;

      stock.batchNames = [
        {
          quantity: Math.min(bs[0].availableQty, child.remain),
          batchName: bs[0].batchName,
        },
      ];
      this.countPrice();
    },
    addNewStock(product) {
      var suggest = product.stocks.leftJoin(product.fulfills, "stockId");
      var stockId = suggest[0].stockId;
      var bs = product.batchNames.filter((i) => i.stockId == stockId);

      var quantityNeed = product.remain - product.quantity;

      if (suggest[0].availableQty < quantityNeed) {
        quantityNeed = suggest[0].availableQty;
      }
      if (quantityNeed < 0) {
        quantityNeed = 0;
      }

      product.fulfills.push({
        stockId: stockId,
        allBatchNames: bs,
        batchNames: [
          {
            quantity: Math.min(quantityNeed, bs[0].availableQty),
            batchName: bs && bs[0] ? bs[0].batchName : "-",
          },
        ],
      });
      this.countPrice();
    },
    addNewBatchName(product, stock) {
      var suggest = stock.allBatchNames.leftJoin(stock.batchNames, "batchName");
      var quantityNeed = product.remain - product.quantity;
      if (suggest[0].availableQty < quantityNeed) {
        quantityNeed = suggest[0].availableQty;
      }
      if (quantityNeed < 0) {
        quantityNeed = 0;
      }
      stock.batchNames.push({
        quantity: quantityNeed,
        batchName: suggest[0].batchName,
      });
      this.countPrice();
    },
    countPrice() {
      this.products.map((product) => {
        [product]
          .concat(product.isBundle ? product.children : [])
          .forEach((p) => {
            p.overStock = false;
            p.missStock = false;
            if (p.noCheckWarehouse || p.isBundle) {
              return true;
            } else {
              p.quantity = 0;
              p.fulfills = (p.fulfills || []).filter(
                (f) => f.batchNames.length > 0
              );
              p.fulfills.forEach((f) => {
                f.batchNames.forEach((i) => {
                  var bath = f.allBatchNames.find(
                    (a) => a.batchName == i.batchName
                  );
                  if (bath) {
                    i.availableQty = bath.availableQty;
                    i.inventoryDate = bath.inventoryDate;
                    i.expiryDate = bath.expiryDate;
                    i.manufacturingDate = bath.manufacturingDate;
                  } else {
                    i.availableQty = undefined;
                  }
                });
                f.quantity = f.batchNames.reduce((a, b) => a + +b.quantity, 0);
                p.quantity += f.quantity;
              });

              if (p.quantity > p.remain) {
                p.overStock = true;
              }
              if (p.quantity < p.remain) {
                p.missStock = true;
              }
              return !!p.fulfills.length;
            }
          });
      });
      this.$forceUpdate();
    },
    closePopup() {
      this.isOpen = false;
      // this._close();
    },
    save() {
      var isWrong = false;
      this.products.map((a) => {
        if (a.missStock || a.overStock) {
          isWrong = true;
        }
        if (a.children) {
          a.children.map((b) => {
            if (b.missStock || b.overStock) {
              isWrong = true;
            }
          });
        }
      });
      if (isWrong) {
        this.$noti.open("Tất cả các sản phẩm cần đủ số lượng cần", true);
        return;
      }

      this._save({ products: this.products, financeData: this.financeData });
    },
  },
};
</script>
