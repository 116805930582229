<template>
  <div v-if="$isMobile()">
    <select
      class="form-control"
      @change="
        check(
          list.find(
            (a) => a.key == $event.target.value || a == $event.target.value
          )
        )
      "
    >
      <option
        v-for="item in list"
        :key="item.key || item"
        :value="item.key || item"
      >
        {{ item.name || item }}
      </option>
    </select>
  </div>
  <span v-else class="option-box-swapper" :class="{ disabled: disabled }">
    <span
      class="option-box"
      v-b-tooltip="item.des"
      @click="check(item)"
      v-for="item in list"
      :key="item.key || item"
      :class="{
        ['option-box-' + size]: !!size,
        active:
          checked == (item.key || item) ||
          checkeds.find((i) => i == (item.key || item)),
      }"
    >
      {{ item.name || item }}
    </span>
  </span>
</template>
<script>
export default {
  name: "CheckBox",
  props: ["list", "value", "title", "multi", "size", "disabled"],
  data() {
    return {
      checked: false,
      checkeds: [],
    };
  },
  mounted() {
    if (this.multi) {
      this.checkeds = this.value || [];
    } else {
      this.checked = this.value || this.list[0].key || this.list[0];
    }
  },
  watch: {
    value() {
      if (this.multi) {
        this.checkeds = this.value || [];
      } else {
        this.checked = this.value || this.list[0].key || this.list[0];
      }
    },
  },
  methods: {
    check(item) {
      console.log(item);
      if (this.multi) {
        if (this.checkeds.find((i) => i == (item.key || item))) {
          this.checkeds = this.checkeds.filter((i) => i != (item.key || item));
        } else {
          this.checkeds.push(item.key || item);
        }

        this.$emit("input", this.checkeds);
        this.$emit("change", this.checkeds);
      } else {
        this.checked = item.key ? item.key : item;
        this.$emit("input", this.checked);
        this.$emit("change", this.checked);
      }
      this.$forceUpdate();
    },
  },
};
</script>