<template>
  <div
    :class="$quickView.isOpen ? 'quick-view open' : 'quick-view'"
    @click.self="$quickView.close()"
    style="width: width"
  >
    <div class="quick-view-content">
      <div class="p-2 bg-white" v-html="$quickView.html"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

const quickView = Vue.observable({
  isOpen: false,
  width: "1200px",
  open: function (html, config) {
    this.isOpen = true;
    this.html = html;
  },
  close: function () {
    this.isOpen = false;
  },
});
Vue.prototype.$quickView = quickView;

export default {
  name: "QuickView",
};
</script>
<style scoped>
.quick-view-content {
  p {
    margin: 0;
  }
  img {
    max-width: 100%;
  }
}
</style>
