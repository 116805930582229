<template>
  <div ref="modal " :class="isOpen ? 'modal open' : 'modal'" @click.self="closePopup()">
    <div class="modal-content sm">
      <div class="header">
        <h4 class="title text-center">Thông tin chốt ca</h4>
      </div>
      <div v-if="loading" class="text-center p-5">
        <br />
        <br />
        <br />
        <i class="fa fa-spinner fa-pulse fa-spin" />
        {{$t('common.loading')}}
        <br />
        <br />
        <br />
      </div>
      <div v-else class="body">
        <div class="flex flex-row align-items-center">
          <div class="flex flex-1">
            <p>
              <b>{{$t('report.time_range')}}:</b>
              {{ $formatTime(dataReport.fromDate) }} - {{ $formatTime(dataReport.endDate) }}
            </p>
            <p>
              <b>{{$t('report.total_bill')}}:</b>
              {{ dataReport.salesReport && dataReport.salesReport.invoiceCounts }}
            </p>
          </div>
          <div class="flex flex-200px">
            <label>{{$t('report.shift_sales_transfer_name')}}:</label>
            <auto-complete 
               v-model="recieverId"
              :list="recieverList.map(i=>({id: i.id, name: i.userName }))"
             />
            <!-- <select class="form-control">
              <option
                v-for="(item, index) in recieverList"
                :key="index"
                :value="item.id"
              >{{ item.userName }}</option>
            </select> -->
          </div>
        </div>

        <table class="w100 table mt-2" v-if="dataReport.salesReport">
          <thead role="rowgroup">
            <tr>
              <th>{{$t('report.revenue_total')}}</th>
              <th class="text-right">Giá trị</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{$t('report.total_money_sale')}}</td>
              <td class="text-right">{{ $formatMoney(dataReport.salesReport.total) }}</td>
            </tr>
            <tr>
              <td>{{$t('report.total_money_promo')}}</td>
              <td class="text-right">{{ $formatMoney(dataReport.salesReport.discountTotal) }}</td>
            </tr>
            <tr>
              <td>{{$t('report.total_real_revenue')}}</td>
              <td class="text-right">{{ $formatMoney(dataReport.salesReport.realRevenueTotal) }}</td>
            </tr>
            <tr @click="showPayment = !showPayment" class="pointer">
              <td>
                <i class="fa fa-chevron-up" v-if="!showPayment" />
                <i class="fa fa-chevron-down" v-if="showPayment" />
                {{$t('report.total_payment')}}
              </td>
              <td class="text-right">{{ $formatMoney(dataReport.salesReport.paymentTotal) }}</td>
            </tr>
            <tr v-if="showPayment">
              <td colspan="2" class="pl-3 pt-0 pb-0 pr-0">
                <table class="table table-sub">
                  <tr class="table-sub-header">
                    <td>
                      <b>{{$t('bill_detail.total_cash')}}</b>
                    </td>
                    <td class="text-right">{{$formatMoney(dataReport.salesReport.cashPaymentTotal)}}</td>
                  </tr>
                  <tr
                    v-for="payment in dataReport.salesReport.cashPaymentDetails"
                    :key="payment.currency"
                  >
                    <td>{{payment.currency}}</td>
                    <td class="text-right">{{$formatMoney(payment.total)}}</td>
                  </tr>
                  <tr class="table-sub-header">
                    <td>
                      <b>{{$t('bill_detail.total_card')}}</b>
                    </td>
                    <td class="text-right">{{$formatMoney(dataReport.salesReport.cardPaymentTotal)}}</td>
                  </tr>
                  <tr
                    v-for="payment in dataReport.salesReport.cardPaymentDetails"
                    :key="payment.currency"
                  >
                    <td>{{payment.cardNumber}}</td>
                    <td class="text-right">{{$formatMoney(payment.total)}}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr @click="isShowDebt = !isShowDebt ">
              <td>
                <i class="fa fa-chevron-up" v-if="!isShowDebt" />
                <i class="fa fa-chevron-down" v-if="isShowDebt" />
                {{$t('report.total_money_debt')}}
              </td>
              <td
                class="text-right"
              >{{$formatMoney(dataReport.salesReport.incurredDebt - dataReport.salesReport.paidDebt )}}</td>
            </tr>

            <tr v-if="isShowDebt">
              <td colspan="2" class="pl-3 pt-0 pb-0 pr-0">
                <table class="table table-sub">
                  <tr>
                    <td>{{$t('report.total_money_first_dept')}}</td>
                    <td class="text-right">{{$formatMoney(dataReport.salesReport.firstDebt)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('report.total_money_incurred_debt')}}</td>
                    <td class="text-right">{{$formatMoney(dataReport.salesReport.incurredDebt)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('report.total_money_paid_debt')}}</td>
                    <td class="text-right">{{$formatMoney(dataReport.salesReport.paidDebt)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('report.total_money_remain_debt')}}</td>
                    <td class="text-right">{{$formatMoney(dataReport.salesReport.remainDebt)}}</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr class="bold">
              <td>Tổng tiền ca</td>
              <td class="text-right">{{$formatMoney(dataReport.salesReport.realRevenueTotal)}}</td>
            </tr>

            <tr class="bold">
              <td>Tổng tiền ngày</td>
              <td class="text-right">{{$formatMoney(dataReport.realRevenueTotalByDate)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <div class="flex flex-row align-items-center action">
          <div class="flex flex-1" />
          <button @click="outClockShiftSales()" :disabled="sending" class="mr-2">
            <i class="fa fa-unlock" /> Chốt ca
          </button>
          <button class="save" @click="outClockShiftSales(true)" :disabled="sending">
            <i class="fa fa-print" /> Chốt ca & in
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/api";
import { printShiftSalesReport } from "@/services/print";
import AutoComplete from './AutoComplete.vue';
export default {
  name: "ShiftSalePopup",
  components: {AutoComplete},
  data: function() {
    return {
      isOpen: false,
      dataReport: {},
      recieverList: [],
      recieverId: "",
      loading: false,
      showPayment: false,
      isShowDebt: false,
      sending: false
    };
  },
  created() {
    this.getRecieverList();
  },
  methods: {
    openPopup() {
      this.isOpen = true;
      this.sending = false;
      this.loading = true;
      this.showPayment = false;
      api.getShiftSalesData(this.$branchId).then(res => {
        this.loading = false;
        this.dataReport = res.data;
      });
    },
    closePopup() {
      this.isOpen = false;
    },
    getRecieverList() {
      api.findUsersByBranch(this.$branchId).then(res => {
        this.recieverList = res.data;
        this.recieverId = this.recieverList[0].id;
      });
    },
    outClockShiftSales(isPrint) {
      this.sending = true;
      api
        .outClockShiftSalesReport(this.$branchId, this.recieverId)
        .then(res => {
          this.$noti.open("Chốt ca thành công");
          this.sending = false;
          this.isOpen = false;
          if (isPrint) {
            printShiftSalesReport(res.data);
          }
        })
        .catch(() => {
          this.sending = false;
        });
    }
  }
};
</script>
 