<template>
  <div
    :class="isOpen ? ' open' : ''"
    class="quick-view"
    @click.self="closePopup()"
  >
    <div
      class="quick-view-content gb-white sm"
      v-if="isOpen"
      style="width: 1000px"
    >
      <div class="px-2 pt-2 header-list bg-white">
        <div class="d-flex align-items-center">
          <div class="mr-2 flex-fill">
            <p class="m-0" v-if="!isSingle">
              <i class="fa fa-users" />
              Đã chọn
              <strong class="font-16">{{ listSelecteds.length }}</strong>
              khách hàng
            </p>
            <h5 class="m-0 no-break font-16 bold" v-if="isSingle">
              Chọn khách hàng
            </h5>
          </div>

          <button
            @click="closePopup()"
            type="button"
            class="btn btn-basic pl-3 pr-3 mr-2"
          >
            Tắt
          </button>
          <button
            type="submit"
            v-if="!isSingle"
            class="btn btn-primary pl-3 pr-3"
            @click="save()"
          >
            <span class="d-none d-sm-inline-block text-white ml-1">
              {{ $t("common.done") }}
            </span>
            <i class="fa fa-arrow-right" />
          </button>
        </div>
        <div class="tabs-border flex flex-row align-items-stretch mt-0">
          <div
            class="tab"
            :class="{ active: tabActive == 'mine' }"
            @click="
              tabActive = 'mine';
              updateParams();
            "
          >
            Khách hàng của tôi
          </div>

          <div
            class="tab"
            v-if="allowViewAllCustomer"
            :class="{ active: tabActive == 'all' }"
            @click="
              tabActive = 'all';
              updateParams();
            "
          >
            Tất cả khách hàng
          </div>

          <div
            v-if="!isSingle"
            class="tab"
            :class="{ active: tabActive == 'selected' }"
            @click="tabActive = 'selected'"
          >
            Khách hàng đã chọn ({{ listSelecteds.length }})
          </div>
        </div>
      </div>

      <DataTableLocal
        v-if="tabActive == 'selected'"
        :fields="fields"
        :data="listSelecteds"
        :rowActions="[]"
        :headerRenderIndex="isSingle ? 0 : 1"
        name="chon-khach-hang"
        :noExport="true"
        :filterLocal="true"
      />

      <DataTable
        v-if="tabActive == 'mine' || tabActive == 'all'"
        ref="dataTable"
        :renderHeaderMobile="renderHeaderMobile"
        :fields="
          isSingle
            ? fields
            : [
                {
                  key: '_',
                  checkbox: (r) =>
                    listSelecteds.map((a) => a.id).indexOf(r.id) > -1,
                  click: (r) => choose(r),
                },
              ].concat(fields)
        "
        :rowActions="[]"
        :headerRenderIndex="isSingle ? 0 : 1"
        nameFunction="getCustomers"
        :paramsQuery="params"
        keySearch="customerName"
        height="calc(100lvh - 127px)"
        exactCode="customerCode"
        name="chon-khach-hang"
        :noExport="true"
        :filterLocal="true"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import DataTableLocal from "./DataTableLocal";
export default {
  name: "SelectProduct",
  components: { DataTable, DataTableLocal },
  data: function () {
    return {
      isMine: true,
      tabActive: "mine",
      allCheck: true,
      allowViewAllCustomer: false,
      isOpen: false,
      error: "",
      itemList: [],
      keySearch: "",
      limit: 25,
      page: 1,
      total: 0,
      totalPage: 1,
      loading: true,
      listSelecteds: [],
      isSingle: false,
      oldKeySearchs: [],
      showDrop: false,
      params: JSON.stringify({ "companies.id": this.$companyId }),
      fields: [],
      rawWhere: {},
      renderHeaderMobile: (row) =>
        `<div class="d-flex align-items-center">
              <img class="avatar" src="${
                row.urlAvatar || "/images/user.png"
              }" />
              <div class="ml-2">
                <label>${row.customerCode}</label>
                <p class="m-0">
                  ${row.customerName} 
                  ${
                    row.userCustomers.length
                      ? '- <i class="mdi mdi-account-check" ></i>'
                      : ""
                  }
                  </p>
                <p class="sub-text"><i class="mdi mdi-phone" ></i> ${
                  row.phoneNumber || "-"
                }</p>
                <p class="sub-text"><i class="mdi mdi-mail" ></i> ${
                  row.email || "-"
                }</p>
                <p class="sub-text"><i class="mdi mdi-map-marker" ></i> ${[
                  row.address,
                  row.ward,
                  row.district,
                  row.city,
                ]
                  .filter((a) => a)
                  .join(", ")}</p>
                <p class="sub-text"><i class="mdi mdi-note"></i> ${
                  row.note || "-"
                }</p>
              </div>
            </div>
            `,
    };
  },
  methods: {
    openPopup(where, config = {}) {
      if (config) {
        this.fields = config.fields || [];
        this.tabActive = config.tabActive || "mine";
        this.allowViewAllCustomer = config.allowViewAllCustomer;
      }
      if (this.$branch.allowViewAllCustomer) {
        this.allowViewAllCustomer = true;
      }
      this.isSingle = config.isSingle || false;
      this.error = "";
      this.rawWhere = where;

      this.listSelecteds = [];
      this.itemList = [];

      this.params = JSON.stringify({
        "companies.id": this.$companyId,
        "staffInCharge.employeeCode":
          this.tabActive == "mine" ? this.$user.employeeCode : undefined,
        ...this.rawWhere,
      });

      this.fields = [
        {
          key: "customerCode",
          title: "Mã khách hàng",
          searchKey: "customerCode_ilike",
          className: "pointer",
          width: "160px",
          click: (r) => this.choose(r),
        },
        {
          title: "Khách hàng",
          searchKey: ["customerCode_ilike", "customerName_ilike", "note_ilike"],
          key: "customerName",
          className: "pointer",
          minWidth: "200px",
          width: "200px",
          click: (r) => this.choose(r),
        },
        {
          title: "Nhân viên phụ trách",
          searchKey: [
            "staffInCharge.fullName_ilike",
            "staffInCharge.employeeCode_ilike",
          ],
          showData: (r) => (r.staffInCharge ? r.staffInCharge.fullName : "-"),
          key: "staffInCharge.fullName",
          className: "pointer no-break",
          minWidth: "100px",
          click: (r) => this.choose(r),
        },
        {
          key: "phoneNumber",
          searchKey: "phoneNumber_ilike",
          className: "no-break pointer",
          click: (r) => this.choose(r),
          minWidth: "100px",
          title: "SĐT",
        },
        {
          title: "MST",
          key: "taxCode",
          searchKey: "taxCode_ilike",
          minWidth: "100px",
          click: (r) => this.choose(r),
          className: "no-break pointer",
        },

        {
          title: "Email",
          key: "email",
          searchKey: "email_ilike",
          className: "no-break pointer",
          click: (r) => this.choose(r),
          minWidth: "100px",
        },
        {
          title: "Liên hệ",
          key: "contact",
          searchKey: "contact_ilike",
          className: "pointer",
          minWidth: "100px",
          click: (r) => this.choose(r),
        },

        {
          key: "customerCompany",
          searchKey: "customerCompany.shortName_ilike",
          className: "pointer",
          minWidth: "140px",
          title: "Công ty",
          click: (r) => this.choose(r),
          showData: (row) =>
            row.customerCompany
              ? `<p class="text-trim-2 box-200">${row.customerCompany.shortName}</p>`
              : "-",
        },
        {
          key: "customerGroup.groupName",
          searchKey: "customerGroup.groupName_ilike",
          className: "pointer",
          title: "Nhóm khách hàng",
          click: (r) => this.choose(r),
          showData: (row) =>
            row.customerGroup
              ? row.customerGroup.groupName +
                (row.customerGroup.discountPercent
                  ? ` <span class="tag tag-sm tag-success-outline h-24px py-1 px-2">-${row.customerGroup.discountPercent}%</span>`
                  : "")
              : "-",
        },

        {
          minWidth: "140px",
          key: "rankName",
          title: this.$t("customer.rank_name"),
          searchType: "multi-select",
          className: "pointer",
          click: (r) => this.choose(r),
          searchList: this.$members.map((a) => ({
            ...a,
            id: a.rankName,
            name: a.rankName,
          })),
        },

        {
          key: "address",
          searchKey: "address_ilike",
          className: "pointer",
          title: this.$t("customer.address"),
          showData: (row) => row.address,
          click: (r) => this.choose(r),
          width: "300px",
        },
      ];
      this.isOpen = true;

      this.functionListener = (e) => {
        if (e.key === "Escape") {
          this.closePopup();
        }
      };
      document.addEventListener("keydown", this.functionListener);

      return new Promise((resolve, reject) => {
        this._save = resolve;
        this._close = reject;
      });
    },
    closePopup() {
      document.removeEventListener("keydown", this.functionListener);
      this.isOpen = false;
      // this._close();
    },
    choose(product, isSelect) {
      if (this.isSingle) {
        this.closePopup();
        this._save(product);
        return;
      }
      const index = this.listSelecteds.map((i) => i.id).indexOf(product.id);
      if (index > -1) {
        if (!isSelect) {
          this.listSelecteds = this.listSelecteds.filter(
            (i) => i.id != product.id
          );
        }
      } else {
        this.listSelecteds.push(product);
      }
      this.updateCheckAll();
    },
    save() {
      this.closePopup();
      this._save(this.listSelecteds);
    },

    updateParams() {
      this.params = JSON.stringify({
        "companies.id": this.$companyId,
        "staffInCharge.employeeCode":
          this.tabActive == "mine" ? this.$user.employeeCode : undefined,
        ...this.rawWhere,
      });
    },
  },
};
</script>
