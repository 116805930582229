<template>
  <div
    :class="isOpen ? 'quick-view open' : 'quick-view'"
    @click.self="closePopup()"
  >
    <div class="quick-view-content">
      <div class="bg-white">
        <div class="flex p-2 flex-row align-items-center">
          <i @click="isOpen = false" class="fa fa-arrow-left font-20 mr-2" />
          <h3 class="bold m-0 font-16 flex flex-1">{{ detail.title }}</h3>

          <button
            v-for="(action, index) in detail.actions"
            :key="action.name + '-' + index"
            @click="
              action.loading = true;
              $forceUpdate();
              action.click().then((res) => {
                action.loading = false;
                $forceUpdate();
              });
            "
            class="btn ml-2 pointer"
            :class="action.className + ' btn btn-primary'"
          >
            <i
              :class="
                action.loading ? 'fa fa-spinner fa-pulse fa-spin' : action.icon
              "
            />
            &nbsp;
            {{ action.name }}
          </button>
        </div>
        <div class="tabs-border mt-0">
          <div class="tabs flex flex-row">
            <div
              @click="tabActive = 'info'"
              :class="{ active: tabActive == 'info' }"
              class="tab"
            >
              Thông tin
            </div>
            <div
              @click="tabActive = 'items'"
              :class="{ active: tabActive == 'items' }"
              class="tab"
            >
              Sản phẩm
            </div>
          </div>
        </div>
      </div>
      <div class="p-2" v-if="tabActive == 'info'">
        <div class="box">
          <template class v-for="infor in detail.infors">
            <div class="box-headline bold" :key="infor.title">{{ infor.title }}</div>
            <div
              class="p-2 flex flex-row"
              v-for="el in infor.children"
              :key="el.name"
            >
              <p v-if="el.name" class="flex flex-1 mb-0">{{ el.name }}:</p>
              <p
                class="bold flex flex-2 mb-0"
                v-if="el.value"
                :class="{ only: !el.name }"
                v-html="el.value"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="p-2" v-if="tabActive == 'items'">
        <DataTableLocal
          height="calc(100lvh - 160px)"
          ref="table"
          :fields="detail.tableCols"
          :dataRaw="detail.items"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataTableLocal from "./DataTableLocal";
export default {
  name: "DetailQuickView",
  components: { DataTableLocal },
  data: function () {
    return {
      isOpen: false,
      detail: {
        items: [],
        tableCols: [],
      },
      loading: false,
      tabActive: "info",
    };
  },
  methods: {
    update(data) {
      this.detail = data;
      this.$forceUpdate();
      this.loading = false;
    },
    openPopup: function (data, isLoading) {
      this.detail = data;
      if (isLoading) {
        this.loading = true;
      }
      this.isOpen = true;
      return new Promise((resolve, reject) => {
        this._ok = resolve;
        this._cancel = reject;
      });
    },
    closePopup: function () {
      this.isOpen = false;
      this._cancel();
    },
  },
};
</script>