var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isMobile())?_c('div',[_c('select',{staticClass:"form-control",on:{"change":function($event){_vm.check(
        _vm.list.find(
          function (a) { return a.key == $event.target.value || a == $event.target.value; }
        )
      )}}},_vm._l((_vm.list),function(item){return _c('option',{key:item.key || item,domProps:{"value":item.key || item}},[_vm._v(" "+_vm._s(item.name || item)+" ")])}),0)]):_c('span',{staticClass:"option-box-swapper",class:{ disabled: _vm.disabled }},_vm._l((_vm.list),function(item){
      var _obj;
return _c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(item.des),expression:"item.des"}],key:item.key || item,staticClass:"option-box",class:( _obj = {}, _obj['option-box-' + _vm.size] = !!_vm.size, _obj.active = _vm.checked == (item.key || item) ||
        _vm.checkeds.find(function (i) { return i == (item.key || item); }), _obj ),on:{"click":function($event){return _vm.check(item)}}},[_vm._v(" "+_vm._s(item.name || item)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }